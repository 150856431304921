<template>
  <default-layout>
    <v-container fluid tag="section">
      <page-loading :show="loadingTransaction || loadingForm" />
      <v-alert type="error" :value="showAlert" dismissible>
        <div v-html="messageAlert" />
      </v-alert>
      <v-row>
        <v-col cols="6">
          <div class="d-flex flex-wrap">
            <transaction-detail
              :detail="transactionDetail"
              :permissions="permissions"
              :allowedActions="allowedActions"
              style="width: 100%"
            ></transaction-detail>
            <transaction-status
              :detail="transactionDetail"
              :permissions="permissions"
              :allowedActions="allowedActions"
              style="width: 100%"
            ></transaction-status>
            <unit-detail
              :detail="unitDetail"
              :permissions="permissions"
              :allowedActions="allowedActions"
              style="width: 100%"
            ></unit-detail>
            <purchase-document
              :detail="purchaseDocumentDetail"
              :permissions="permissions"
              :allowedActions="allowedActions"
              style="width: 100%"
            ></purchase-document>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="d-flex flex-wrap">
            <agent-detail
              :detail="agentDetail"
              :permissions="permissions"
              :allowedActions="allowedActions"
              style="width: 100%"
            ></agent-detail>
            <owner-detail
              :detail="ownerDetail"
              :permissions="permissions"
              :allowedActions="allowedActions"
              style="width: 100%"
            ></owner-detail>
            <client-detail
              :detail="clientDetail"
              :permissions="permissions"
              :allowedActions="allowedActions"
              style="width: 100%"
            ></client-detail>
            <commission-detail
              :detail="commissionDetail"
              :permissions="permissions"
              :allowedActions="allowedActions"
              style="width: 100%"
            ></commission-detail>
          </div>
        </v-col>
      </v-row>
      <billing-timeline
        :detail="billings"
        :permissions="permissions"
        :allowedActions="allowedActions"
        style="width: 100%"
      ></billing-timeline>
    </v-container>
    <modal-purchase-document></modal-purchase-document>
    <modal-commission></modal-commission>
    <modal-billing></modal-billing>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
const TransactionDetail = () =>
  import('@/components/transaction-management/transaction/transaction-detail');
const TransactionStatus = () =>
  import('@/components/transaction-management/transaction/transaction-status');
const UnitDetail = () => import('@/components/transaction-management/transaction/unit-detail');
const PurchaseDocument = () =>
  import('@/components/transaction-management/transaction/purchase-document');
const AgentDetail = () => import('@/components/transaction-management/transaction/agent-detail');
const OwnerDetail = () => import('@/components/transaction-management/transaction/owner-detail');
const ClientDetail = () => import('@/components/transaction-management/transaction/client-detail');
const CommissionDetail = () =>
  import('@/components/transaction-management/transaction/commission-detail');
const BillingTimeline = () =>
  import('@/components/transaction-management/transaction/billing-timeline');
const ModalPurchaseDocument = () => import('@/components/modals/modal-purchase-document');
const ModalCommission = () => import('@/components/modals/modal-commission');
const ModalBilling = () => import('@/components/modals/modal-billing');
import { mapState } from 'vuex';

export default {
  components: {
    DefaultLayout,
    PageLoading,
    TransactionDetail,
    TransactionStatus,
    UnitDetail,
    PurchaseDocument,
    AgentDetail,
    OwnerDetail,
    ClientDetail,
    CommissionDetail,
    BillingTimeline,
    ModalPurchaseDocument,
    ModalCommission,
    ModalBilling,
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      messageAlert: null,
    };
  },
  computed: {
    ...mapState({
      loadingTransaction: (state) => state.transaction.loading,
      loadingForm: (state) => state.transaction.form.loading,
      permissions: (state) => state.transaction.permissions,
      allowedActions: (state) => state.transaction.allowedActions,
      transactionDetail: (state) => state.transaction.detail,
      unitDetail: (state) => state.transaction.unit,
      purchaseDocumentDetail: (state) => state.transaction.purchaseDocument,
      agentDetail: (state) => state.transaction.agent,
      ownerDetail: (state) => state.transaction.owner,
      clientDetail: (state) => state.transaction.client,
      commissionDetail: (state) => state.transaction.commission,
      billings: (state) => state.transaction.billings,
    }),
  },
  async mounted() {
    await this.loadDetail();
  },
  methods: {
    async refreshAction() {
      await this.loadDetail();
    },
    async loadDetail() {
      try {
        this.$store.commit('transaction/RESET_DEFAULT');
        const response = await this.$store.dispatch(
          'transaction/getDetailInitData',
          this.$route.params.uuid,
        );
        console.log('DETAIL TRANSAKSI: ', response);
      } catch (e) {
        this.showAlert = true;
        this.messageAlert = e;
      }
    },
  },
};
</script>
